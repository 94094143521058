/** Vendors */
import axios from "axios";

window.URL = window.URL || window.webkitURL;

axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API_ENDPOINT;
axios.defaults.headers.common["X-Api-Key"] =
  import.meta.env.VITE_REACT_APP_API_KEY;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.interceptors.response.use(
  function (response) {
    if (!Object.prototype.hasOwnProperty.call(response, "data")) {
      return response;
    }
    console.groupEnd();
    return response.data;
  },
  function (error) {
    console.group("API Errors");
    console.log("Error Status Code: ", error.status);
    switch (error.status) {
      case 401:
        console.log("Failed auth");
        break;
      case 403:
        console.groupEnd();
        return Promise.reject({ error: error.message });
      default:
        console.groupEnd();
        return Promise.reject({ error: error.message });
    }
  }
);

/* Web App Install Event */
window.addEventListener("beforeinstallprompt", (e) => {
  if ("navigator" in window && "getInstalledRelatedApps" in window.navigator) {
    (async function () {
      const relatedApps = await navigator.getInstalledRelatedApps();
      let match = relatedApps.find((app) => {
        //If match its already installed.
        return app.url.includes(window.location.origin);
      });
      if (!match) {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();

        // Stash the event so it can be triggered later.
        window.installEvent = e;
      }
    })();
  }
});

window.addEventListener("appinstalled", () => console.log("App Installed."));

//Only add google maps once.
window.addEventListener("load", () => {
  if (
    typeof window.google !== "object" ||
    typeof window.google.maps !== "object"
  ) {
    let script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      import.meta.env.VITE_REACT_APP_MAP_KEY
    }&libraries=places`;
    document.head.appendChild(script);
  }
});
