/** Custom Methods */
import { formatName } from "../../../dist/js/support";

const schema = (props = {}) => {
  const now = new Date();
  return {
    json: JSON.stringify([
      {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        author: [
          {
            "@type": "Person",
            name: formatName(props.details.author),
          },
        ],
        datePublished: new Date(
          props.details.article.created || now
        ).toISOString(),
        dateModified: new Date(
          props.details.article.updated || now
        ).toISOString(),
        headline: props.details.article.title || "Missing Article Title",
        url: `https://www.tractic.io/education/articles/${props.details.article.handle}`,
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: "https://www.tractic.io",
            name: "Home",
          },
          {
            "@type": "ListItem",
            position: 2,
            item: "https://www.tractic.io/education",
            name: "Education",
          },
          {
            "@type": "ListItem",
            position: 3,
            item: "https://www.tractic.io/education/articles",
            name: "Articles",
          },
          {
            "@type": "ListItem",
            position: 4,
            item: `https://www.tractic.io/education/articles/${props.details.article.handle}`,
            name: props.details.article.title || "Missing Article Title",
          },
        ],
      },
    ]),
    description:
      props.details.article.description || "Missing Article Description",
    title: props.details.article.title || "Missing Article Title",
  };
};
export default schema;
