/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "@redux/configureStore";

const testimonial = createReducer(initialState.testimonial, (builder) => {
  builder
    .addCase(
      createAction(types.GET_TESTIMONIAL_SUCCESS),
      (state: IRootState["testimonial"], action: AnyAction) => {
        return { ...state, details: action.testimonial };
      }
    )
    .addCase(
      createAction(types.SEARCH_TESTIMONIALS_SUCCESS),
      (state: IRootState["testimonial"], action: AnyAction) => {
        return { ...state, list: action.results };
      }
    )
    .addDefaultCase((state) => state);
});

export default testimonial;
