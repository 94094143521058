import React from "react";

/** Vendors */
import { Helmet } from "react-helmet";

/** Schema for pages */
import article from "./article";
import articles from "./articles";
import contact from "./contact";
import definitions from "./definitions";
import education from "./education";
import faq from "./faq";
import home from "./home";
import legal from "./legal";
import pricing from "./pricing";

export default function HeaderInfo({ details, page }) {
  let content = () => {
    return new Object();
  };

  /** Step 1. Determine which page */
  if (page === "article") content = article;
  if (page === "articles") content = articles;
  if (page === "contact") content = contact;
  if (page === "definitions") content = definitions;
  if (page === "education") content = education;
  if (page === "faq") content = faq;
  if (page === "home") content = home;
  if (page === "legal") content = legal;
  if (page === "pricing") content = pricing;

  /** Step 2. Find required data to make up page header. */
  const { author, description, json, title } = content({
    details,
    page,
  });

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
      {author && <meta content={author} property="og:author" />}
      <meta content={title} property="og:title" />
      <meta content={window.location.href} property="og:url" />
      <meta content={description} name="description" />
      <script type="application/ld+json">{json}</script>
    </Helmet>
  );
}
