import { useEffect, useRef, useState } from "react";

/** Vendors */
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Button, Carousel, Col, Progress, Row, Typography } from "antd";
import {
  PauseOutlined,
  PlayCircleOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

/** Custom Assets */
import REITool from "@dist/img/brand/every-rei-tool.png";

/** Custom CSS */
import "./podcast.css";

/** Types */
interface IAudioFile {
  description: string;
  image: string;
  key: string;
  src: string;
  title: string;
  youtube?: string;
}

dayjs.extend(duration);

function AudioSelect({ description, image, src, title, youtube }: IAudioFile) {
  const [player, setPlayer] = useState<{
    duration: string;
    percent: number;
    playing: boolean;
    time: string;
  }>({
    duration: "0:00",
    percent: 0,
    playing: false,
    time: "0:00",
  });
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        if (audioRef.current) {
          audioRef.current.src = URL.createObjectURL(blob);
          audioRef.current.load();
          actions.listeners();
        }
      });
  }, [src]);

  const actions = {
    play: () => {
      setPlayer((state) => {
        if (audioRef.current) {
          if (!state.playing) {
            audioRef.current.play();
          } else {
            audioRef.current.pause();
          }
        }
        return { ...state, playing: !state.playing };
      });
    },
    listeners: () => {
      audioRef.current?.addEventListener("timeupdate", function () {
        if (audioRef.current) {
          const currentTime = audioRef.current.currentTime || 1;
          const duration = audioRef.current.duration || 1;
          setPlayer((player) => ({
            ...player,
            duration: dayjs
              .duration(duration * 1000, "milliseconds")
              .format("m:ss"),
            percent: ((currentTime + 0.25) / duration) * 100,
            time: dayjs
              .duration(currentTime * 1000, "milliseconds")
              .format("m:ss"),
          }));
        }
      });
    },
  };

  return (
    <div className="select-option">
      <div className="info">
        <div className="holdme">
          <img height={80} src={image} width={80} />
        </div>
        <div className="holdme">
          <div
            className="button pointer"
            onClick={actions.play}
            title="Play podcast"
          >
            {player.playing ? (
              <PauseOutlined style={{ fontSize: 40 }} />
            ) : (
              <PlayCircleOutlined style={{ fontSize: 40 }} />
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <Typography.Title level={2}>{title}</Typography.Title>
        <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }}>
          {description}
        </Typography.Paragraph>
        <div className="progress">
          <div className="time current">{player.time}</div>
          <Progress
            percent={player.percent}
            showInfo={false}
            strokeColor="var(--color14)"
          />
          <div className="time duration">
            {player.duration}{" "}
            {youtube ? (
              <Button
                className="youtube"
                icon={<YoutubeOutlined />}
                onClick={() => window.open(youtube)}
              >
                YouTube
              </Button>
            ) : null}
          </div>
        </div>
        <audio autoPlay={false} className="hidden" ref={audioRef} />
      </div>
    </div>
  );
}

export default function Podcasts() {
  const files: IAudioFile[] = [
    {
      description:
        "Greg Namrow is a passionate and dedicated founder and entrepreneur with a proven track record in launching and scaling successful startups in business, Real Estate investing, and PropTech. As a forward-thinking leader and Marine Corps Veteran, I pride myself on my ability to stay ahead of industry trends and leverage emerging technologies to create innovative solutions that add value to the market.",
      image:
        "https://reifoxhole.buzzsprout.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCRGJhUUFRPSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--6b154d8456a39af01f30ed19a016277c129de59f/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2QzNKbGMybDZaVWtpRHpFME1EQjRNVFF3TUY0R093WlVPZ3huY21GMmFYUjVTU0lMWTJWdWRHVnlCanNHVkRvTFpYaDBaVzUwU1NJT01UUXdNSGd4TkRBd0Jqc0dWRG9NY1hWaGJHbDBlV2xWT2c5amIyeHZjbk53WVdObFNTSUpjMUpIUWdZN0JsUT0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--ba61da96b1aafb226473d067fb9b416582e45878/Collage%20With%20Happy%20People%20In%20Headphones%20Podcast%20Cover-2.jpg",
      key: "audio-file-0",
      src: "https://assets.tractic.io?lvl1=audio&lvl2=20230719-foxhole-episode-10x-returns.mp3",
      title: "Real Estate from the Foxhole w/ Oliver Perry",
    },
    {
      description:
        "Greg shares three things that make TRACTIC unique: the dynamic accountability and efficiency settings, the reduced workload it provides for users, and its ability to help investors make data-driven decisions to improve their portfolio performance. Whether you are an investor who just purchased your first property or you have owned multiple properties for many years, TRACTIC’s dynamic capabilities can help you analyze the performance of your real estate portfolio in real-time. It’s great to see Greg excel with his PropTech platform and we wish him success as TRACTIC continues to grow!",
      image:
        "https://images.libsyn.com/p/assets/c/e/a/7/cea768523e558e5d/SABMGroup_square.jpg",
      key: "audio-file-1",
      src: "https://assets.tractic.io?lvl1=audio&lvl2=20230901-service-academy-episode-261.mp3",
      title: "Creating a Real Estate Asset Management Platform",
    },
    {
      description:
        "By using Tractic, you'll be able to access all of your investment data in one centralized location, making it easy to track your progress and make decisions quickly and easily. With Tractic, you'll be able to make your real estate investments work for you!",
      image: REITool,
      key: "audio-file-2",
      src: "https://assets.tractic.io?lvl1=audio&lvl2=20230808-every-rei-tool-product-demo.mp3",
      title: "Every REI Tool TRACTIC Product Demo",
      youtube: "https://www.youtube.com/watch?v=E-kevnS8eMQ",
    },
  ];

  return (
    <div className="podcast shape-after">
      <Row align="middle" justify="center">
        <Col lg={18} md={20} sm={20} xs={24}>
          <Carousel
            className="select"
            dotPosition={window.innerWidth < 768 ? "top" : "left"}
          >
            {files.map((file: IAudioFile) => (
              <AudioSelect {...file} key={file.key} />
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}
