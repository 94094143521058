/** Vendor */
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

/** Custom Reducers */
import rootReducer from "./reducers/index";

/** Custom APIs */
import article from "../api/article";
import definition from "../api/definition";
import platform from "../api/platform";
import stripe from "../api/stripe";
import testimonial from "../api/testimonial";
import video from "../api/video";

/** Types */
import type { ThunkAction } from "redux-thunk";

import type {
  Action,
  AnyAction,
  DevToolsEnhancerOptions,
  Middleware,
} from "@reduxjs/toolkit";

declare module "redux" {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const middleware: Array<Middleware> = [thunk];

const devToolsEnhancer: DevToolsEnhancerOptions = {
  trace: true,
  traceLimit: 25,
};

const env = import.meta.env.VITE_NODE_ENV as string;

const extraArgument = {
  article,
  definition,
  platform,
  stripe,
  testimonial,
  video,
};

const store = configureStore({
  devTools: env !== "prod" ? devToolsEnhancer : false,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
    }).concat(middleware);
  },
});

// Infer the `IRootState` and `IAppDispatch` types from the store itself
export type IAppApi = typeof extraArgument;

export type IGetState = typeof store.getState;

export type IRootState = ReturnType<IGetState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type IAppDispatch = typeof store.dispatch;

export type { AnyAction };

export default store;
