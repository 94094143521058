import React from "react";

/** Vendor */
import { Button, Col, Image, Row, Typography } from "antd";

/** Custom CSS */
import "../../../dist/css/notFound.css";

/** Image */
import ErrorImage from "../../../dist/img/404.png";

function NotFound() {
  return (
    <Row className="notFound" align="middle" justify="center">
      <Col className="maxWidth1600" xs={20} xl={20}>
        <Row align="middle" justify="center">
          <Col className="errorHeading" style={{ maxWidth: "700px" }}>
            <Typography.Title level={2}>OOPS!</Typography.Title>
            <Typography.Text>PAGE NOT FOUND</Typography.Text>
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Image
            alt="error.png"
            preview={false}
            src={ErrorImage}
            style={{
              display: "flex",
              height: "auto",
              margin: "50px auto",
              maxWidth: "800px",
              width: "80%",
            }}
          />
        </Row>
        <Row align="middle" justify="center">
          <Col>
            <Button
              className="backHomeBtn"
              href="/"
              size="large"
              type="primary"
            >
              Back to tractic.io
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NotFound;
