import React from "react";

/** Vendors */
import { App, ConfigProvider, Layout, ThemeConfig } from "antd";
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

/** Custom Components */
import AppLayout from "./components/shared/layout/AppLayout";
import Home from "./components/app/views/Home";
import NotFound from "./components/shared/views/NotFound";

const ArticleDetail = lazy(() => import("@components/education/views/Article"));
const Articles = lazy(() => import("@components/education/views/Articles"));
const ContactUs = lazy(() => import("@components/contact/views/Contact"));
const Definition = lazy(() => import("@components/education/views/Definition"));
const Education = lazy(() => import("@components/education/views/Education"));
const Faqs = lazy(() => import("@components/app/views/FAQ"));
const Legal = lazy(() => import("@components/app/views/Legal"));
const Pricing = lazy(() => import("@components/pricing/views/Pricing"));
const Subscription = lazy(() => import("@components/subscription/views/Setup"));
const Tutorials = lazy(() => import("@components/education/views/Tutorials"));

//https://ant.design/docs/react/customize-theme
const antdTheme: ThemeConfig = {
  token: {
    borderRadius: 5,
    colorBgBase: "#fff",
    colorError: "#5b5636",
    colorInfo: "#2b7f3e",
    colorLink: "#2b7f3e",
    colorPrimary: "#2b7f3e",
    colorPrimaryBg: "#fff",
    colorPrimaryHover: "#2b7f3e",
    colorText: "#2C2C2C",
    colorTextBase: "#333",
    colorTextSecondary: "#6E6E6E",
    colorTextDescription: "#6E6E6E",
    lineHeight: 1.5,
    fontFamily: "Montserrat",
  },
};

function Main() {
  return (
    <ConfigProvider theme={antdTheme}>
      <App>
        <Layout className="site-layout">
          <Suspense fallback={<React.Fragment>Loading</React.Fragment>}>
            <Routes>
              <Route path="/" element={<AppLayout />}>
                <Route path="" element={<Home />} />
                <Route path="contact" element={<ContactUs />} />
                <Route path="education" element={<Education />} />
                <Route path="education/articles" element={<Articles />} />
                <Route
                  path="education/articles/:handle"
                  element={<ArticleDetail />}
                />
                <Route path="education/definitions" element={<Definition />} />
                <Route
                  path="education/definitions/:handle"
                  element={<Definition />}
                />
                <Route path="education/tutorials" element={<Tutorials />} />
                <Route path="faqs" element={<Faqs />} />
                <Route path="legal" element={<Legal />} />
                <Route
                  path="login/success"
                  element={<div>Successfully Logged In</div>}
                />
                <Route path="pricing" element={<Pricing />} />
                <Route path="subscription" element={<Subscription />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </Layout>
      </App>
    </ConfigProvider>
  );
}

export default Main;
