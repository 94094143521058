import React, { ReactNode, useState } from "react";

/** Vendor */
import { Button, Col, Collapse, Grid, Row, Typography } from "antd";

/** Custom Data */
import questions from "@dist/data/Questions";

/** Custom Image */
import "./questions.css";

/** Types */
import type { RowProps } from "antd/lib/row";

interface IFAQ {
  answer: string;
  extras?: ReactNode;
  key: string;
  question: string;
}

interface IFAQPayload {
  faqs: IFAQ[];
}

interface IFAQProps {
  initLoadCount?: number;
  justify?: RowProps["justify"];
  showBtn?: boolean;
  showHeader?: boolean;
}

const { useBreakpoint } = Grid;

function DesktopFAQAccordion({ faqs }: IFAQPayload) {
  return (
    <React.Fragment>
      {faqs.map((faq: IFAQ) => (
        <Col key={faq.key} xs={24}>
          <br />
          <br />
          <Typography.Title level={5}>{faq.question}</Typography.Title>
          <Typography.Paragraph className="text-left" type="secondary">
            {faq.answer} {faq.extras}
          </Typography.Paragraph>
        </Col>
      ))}
    </React.Fragment>
  );
}

function MobileFAQAccordion({ faqs }: IFAQPayload) {
  return (
    <Collapse
      accordion
      bordered
      defaultActiveKey={["1"]}
      expandIcon={({ isActive }) => (
        <p style={{ fontSize: "20px" }}>{isActive ? "--" : "+"}</p>
      )}
      expandIconPosition="end"
      ghost
      items={faqs.map((faq) => ({
        key: faq.key,
        label: faq.question,
        children: (
          <Typography.Paragraph type="secondary" className="text-left xs-font">
            {faq.answer} {faq.extras}
          </Typography.Paragraph>
        ),
      }))}
    />
  );
}

function Questions({
  initLoadCount = 7,
  justify = "center",
  showHeader = true,
}: IFAQProps) {
  const [faqs, setFaqs] = useState(questions.slice(0, initLoadCount));
  const screens = useBreakpoint();

  return (
    <Row align="middle" justify={justify}>
      <Col>
        {showHeader && (
          <Row align="middle" className="w-100" justify="center">
            <Typography.Title level={2}>
              Frequently Asked Questions
            </Typography.Title>
          </Row>
        )}
        <Row align="middle" className="accordion" justify="center">
          <Col className="maxWidth1600" xs={24}>
            {screens.md ? (
              <DesktopFAQAccordion faqs={faqs} />
            ) : (
              <MobileFAQAccordion faqs={faqs} />
            )}
          </Col>
        </Row>
        <br />
        <Row align="middle" justify="center">
          {questions.length > faqs.length && (
            <Button
              onClick={() => setFaqs(questions)}
              size="middle"
              target="_blank"
              type={screens.md ? "primary" : "link"}
            >
              {screens.md ? (
                "Read More FAQ's"
              ) : (
                <u className="sm-font">Load More</u>
              )}
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default Questions;
