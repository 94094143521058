import React from "react";

/** Vendors */
import { Col, Flex } from "antd";

/** Custom Components */
import Demonstration from "../cards/Demonstration";
import Features from "../cards/Features";
import HeaderInfo from "../../shared/schema/Header";
import Hero from "../cards/Hero";
import Overview from "../cards/Overview";
import Partners from "../../shared/card/Partners";
import PlatformMetrics from "../../shared/card/PlatformMetrics";
import Podcasts from "../cards/Podcasts";
import Problems from "../cards/Problems";
import Questions from "../cards/Questions";
import Testimonials from "../../shared/card/Testimonials";

export default function Home() {
  return (
    <React.Fragment>
      <HeaderInfo details={{ pagename: "Home" }} page="home" />
      <Col style={{ backgroundColor: "white" }}>
        <Hero />
        <Partners />
        <Problems />
        <Flex gap={50} vertical>
          <PlatformMetrics />
          <Overview />
          <Features />
          <Testimonials />
          <Podcasts />
          <Questions />
          <Demonstration />
        </Flex>
      </Col>
    </React.Fragment>
  );
}
