import React from "react";

/** Vendors **/
import { Layout } from "antd";
import { Outlet } from "react-router-dom";


/** Custom Components **/
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

function AppLayout() {
  return (
    <React.Fragment>
      <Navbar />
      <Layout.Content>
            <Outlet />
      </Layout.Content>
      <Footer />
    </React.Fragment>
  );
}
export default AppLayout;
