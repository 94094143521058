/** Vendor */
import { CheckCircleFilled } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";

/** Images */
import ImgFeature from "@dist/img/app/feature-img.jpg";

/** Custom CSS */
import "./features.css";

const features = [
  {
    description:
      "When tax time arrives, TRACTIC will provide you with personalized reporting including an income statement, a net cash flow report, an organized Schedule E, & more!",
    key: "feature-0",
    title: "Reporting & Tax Time Considerations",
  },
  {
    description:
      "TRACTIC offers a secure document management hub, allowing subscribers to store and share important documents such as lease agreements, vendor contracts, and tenant applications.",
    key: "feature-1",
    title: "Documentation Management",
  },
  {
    description:
      "Uploading owner statements and reporting from 1 or even 10+ years ago can be difficult. So, why not allow the TRACTIC Team to support you! Allow our team to upload ALL of your Real Estate documentation and update your TRACTIC account in accordance with your subscription!",
    key: "feature-2",
    title: "White Glove Concierge",
  },
];

function Features() {
  return (
    <div className="feature mt-4 mb-4 shape-before shape-after">
      <Row justify="center">
        <Col lg={12} md={24} xs={24}>
          <Typography.Title className="text-color-9" level={2}>
            Just a Few Features..
          </Typography.Title>
          <Typography.Text className="sm-font text-color-11">
            TRACTIC is fully responsive, you can keep track of your Real Estate
            assets on any platform, including your smartphone, desktop/PC, and
            tablet.
          </Typography.Text>
          <br />
          {features.map((item) => (
            <div key={item.key}>
              <Row>
                <Col md={24} xs={0}>
                  <br />
                </Col>
                <Space align="center" size="large">
                  <Col xs={2}>
                    <CheckCircleFilled
                      style={{ color: "var(--color11)", fontSize: "2rem" }}
                    />
                  </Col>
                  <Col xs={22}>
                    <Typography.Title
                      className="text-color-11 text-left"
                      level={4}
                    >
                      {item.title}
                    </Typography.Title>
                    <Typography.Text className="sm-font text-color-11 text-left">
                      {item.description}
                    </Typography.Text>
                  </Col>
                </Space>
              </Row>
            </div>
          ))}
        </Col>
        <Col lg={12} md={24} xs={0} className="feature-image">
          <img src={ImgFeature} />
        </Col>
      </Row>
    </div>
  );
}

export default Features;
