import ReactDOM from "react-dom/client";

/** Vendors */
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

/** Custom CSS */
import "katex/dist/katex.min.css";
import "./dist/css/colors.css";
import "./dist/css/index.css";
import "./dist/css/app.css";
import "./dist/css/buttons.css";
import "./dist/css/cards.css";
import "./dist/css/colors.css";
import "./dist/css/form.css";
import "./dist/css/format.css";
import "./dist/css/text.css";

/** Redux */
import store from "./redux/configureStore";

/** Axios Config */
import "./dist/js/setup";

/** Custom Components */
import App from "./App";

const root = document.getElementById("root");

ReactDOM.createRoot(root!).render(
  <Auth0Provider
    authorizationParams={{
      redirect_uri: import.meta.env.VITE_REACT_APP_PRIVATE_URL,
    }}
    clientId={import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID}
    domain={import.meta.env.VITE_REACT_APP_AUTH0_URL}
  >
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </Auth0Provider>
);
