/** Vendors */
import { combineReducers, createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "@dist/js/redux";

/** Types */
import type { AnyAction, IRootState } from "@redux/configureStore";

const details = createReducer(initialState.member.details, (builder) => {
  builder
    .addCase(
      createAction(types.GET_MEMBER_SUCCESS),
      (state: IRootState["member"]["details"], action: AnyAction) => {
        return { ...state, ...action.member };
      }
    )
    .addCase(
      createAction(types.UPDATE_MEMBER_SUCCESS),
      (state: IRootState["member"]["details"], action: AnyAction) => {
        return redux.updateMatchCase(state, action, "member");
      }
    )
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.member.list, (builder) => {
  builder
    .addCase(
      createAction(types.SEARCH_MEMBERS_SUCCESS),
      (_: IRootState["member"]["list"], action: AnyAction) => {
        return action.results;
      }
    )
    .addDefaultCase((state) => state);
});

const stripe = createReducer(initialState.member.stripe, (builder) => {
  builder
    .addCase(
      createAction(types.STRIPE_SESSION_CREATE_SUCCESS),
      (state: IRootState["member"]["stripe"], action: AnyAction) => ({
        ...state,
        ...action.stripe,
      })
    )
    .addCase(
      createAction(types.STRIPE_SUBSCRIPTION_CREATE_SUCCESS),
      (state: IRootState["member"]["stripe"], action: AnyAction) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_UPDATE_SUBSCRIPTION_SUCCESS),
      (state: IRootState["member"]["stripe"], action: AnyAction) => {
        return { ...state, ...action.stripe };
      }
    )
    .addCase(
      createAction(types.STRIPE_VERIFY_PROMO_SUCCESS),
      (state: IRootState["member"]["stripe"], action: AnyAction) => ({
        ...state,
        ...action.stripe,
      })
    )
    .addDefaultCase((state) => state);
});

const member = combineReducers({
  details,
  list,
  stripe,
});

export default member;
