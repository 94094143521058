import React, { useState } from "react";

/** Vendors */
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { Button, Layout, Menu } from "antd";

/** Custom Logo */
import LogoTextStackedRight from "@dist/img/brand/tractic-logo-text-stacked-right.png";

/** Custom CSS */
import "./navbar.css";

function Navbar() {
  const [current, setCurrent] = useState("home");

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const items = [
    {
      key: "home",
      label: <Link to="/">Home</Link>,
    },
    {
      key: "education",
      label: <Link to="/education">Education</Link>,
    },
    {
      key: "pricing",
      label: <HashLink to="/pricing">Pricing</HashLink>,
    },
    {
      key: "faqs",
      label: <HashLink to="/faqs">FAQs</HashLink>,
    },
    {
      key: "login",
      label: (
        <Button
          href={`${import.meta.env.VITE_REACT_APP_PRIVATE_URL}/dashboard`}
          style={{ color: "#fff" }}
          type="primary"
        >
          Log In
        </Button>
      ),
    },
  ];

  return (
    <Layout.Header id="top" className="header flex flex-row flex-center">
      <Link className="h-100 flex flex-column-center" to="/">
        <img
          alt="TRACTIC"
          className="tractic-logo-text"
          style={{ height: 50 }}
          src={LogoTextStackedRight}
        />
      </Link>
      <Menu
        className="ml-auto"
        items={items}
        mode="horizontal"
        onClick={onClick}
        selectedKeys={[current]}
        style={{ minWidth: window.innerWidth < 768 ? 0 : 480 }}
      />
    </Layout.Header>
  );
}

export default Navbar;
