/** Vendors */
import { Col, Row } from "antd";

/** Custom Components */
import QuestionsCard from "../../shared/card/Questions";

function QuestionSection() {
  return (
    <Row justify="center">
      <Col lg={16} md={20} sm={20} xs={22}>
        <QuestionsCard />
      </Col>
    </Row>
  );
}

export default QuestionSection;
