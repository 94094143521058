/** Vendors */
import axios from "axios";

/** Enums */
import { ResourceId } from "../types";

/** Types */
import type {
  IArticle,
  IArticleResponse,
  IArticleVisited,
  ISearchArticles,
  ISearchArticlesResponse,
} from "../types";

async function articleVisited({ handle, resource_name }: IArticleVisited) {
  try {
    return await axios.post<IArticleVisited, void>("/article/visited", {
      article: {
        handle,
        resource_id: ResourceId.Article,
        resource_name,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

async function getArticle(article: IArticle) {
  try {
    return await axios.get<IArticle, IArticleResponse>("/article", {
      params: {
        handle: article?.handle,
        resource_id: ResourceId.Article,
        resource_name: article.resource_name,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchArticles(params = {}) {
  return await axios.get<ISearchArticles, ISearchArticlesResponse>(
    "/article/search",
    { params }
  );
}

async function upvoteArticle(article: IArticle) {
  return await axios.post<IArticle, IArticleResponse>("/article/upvote", {
    article,
  });
}

const article = {
  get: {
    article: getArticle,
  },
  post: {
    upvote: upvoteArticle,
    visit: articleVisited,
  },
  search: searchArticles,
};

export default article;
