const schema = () => {
  return {
    json: JSON.stringify([
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "Education",
        url: "https://www.tractic.io/education",
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: "https://www.tractic.io",
            name: "Home",
          },
          {
            "@type": "ListItem",
            position: 2,
            item: "https://www.tractic.io/education",
            name: "Education",
          },
        ],
      },
    ]),
    description: "Collection of knowledge about the PropTech industry",
    title: "Education",
  };
};
export default schema;
