import { useEffect, useState } from "react";

/** Vendors */
import { Empty, Grid, Select, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

/** Redux */
import { searchTestimonialAction } from "@redux/actions/testimonial";

/** Custom Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom Methods */
import { formatName, testimonialCategories } from "@dist/js/support";

/** Custom CSS */
import "./testimonial.css";

/** Enums */
import { TestimonialCategory } from "types";

/** Types */
import type { IRootState } from "@redux/configureStore";
import type { IMember, ISearchTestimonials, ITestimonial } from "types";

interface IPointer {
  size: number;
  start: number;
  total: number;
}

export default function Testimonials() {
  const [limits, setLimits] = useState<IPointer>({
    size: 3,
    start: 0,
    total: 0,
  });
  const breakpoints = Grid.useBreakpoint();
  const dispatch = useAppDispatch();

  const { members, testimonials } = useAppSelector((state: IRootState) => ({
    members: state.member.list,
    testimonials: state.testimonial.list,
  }));

  useEffect(() => {
    /** Step 1. Determine screen size and set inc limit */
    actions.onRefresh({ category: TestimonialCategory.Overall });
  }, []);

  useEffect(() => {
    let size = 3;
    if (breakpoints.xxl) size = 5;
    if (breakpoints.xl) size = 5;
    if (breakpoints.lg) size = 4;
    if (breakpoints.sm) size = 2;
    if (breakpoints.xs) size = 1;

    setLimits({ size, start: 0, total: testimonials.length - 1 });
  }, [testimonials?.length]);

  const actions = {
    findMember: (testimonial: ITestimonial) => {
      return members.find((member: IMember) => {
        return member.resource_name === testimonial.author;
      });
    },
    onBack: () => {
      setLimits((state) => ({
        ...state,
        start: state.start - state.size < 0 ? 0 : state.start - state.size,
      }));
    },
    onChangeCategory: (category: string) => {
      actions.onRefresh({ category });
    },
    onNext: () => {
      setLimits((state) => ({
        ...state,
        start:
          state.start + state.size > state.total ? 0 : state.start + state.size,
      }));
    },
    onRefresh: (params: ISearchTestimonials) => {
      dispatch(searchTestimonialAction(params));
    },
  };

  const nextBatch = testimonials.slice(
    limits.start,
    limits.start + limits.size
  );

  return (
    <div className="testimonials">
      <div className="header">
        <Typography.Title className="m-0" level={3}>
          Success Stories from TRACTIC Users
        </Typography.Title>
        <Select
          defaultValue="overall"
          onChange={actions.onChangeCategory}
          options={testimonialCategories}
          size="large"
          style={{ width: 240 }}
        />
      </div>
      <div className="list">
        <div className="button left" onClick={actions.onBack}>
          <LeftOutlined />
        </div>
        <div className="content">
          {nextBatch.length === 0 ? (
            <div className="testimonial">
              <Empty
                description={
                  <span>Select a different category to see more</span>
                }
              />
            </div>
          ) : (
            nextBatch.map((testimonial: ITestimonial) => (
              <div className="testimonial" key={testimonial.resource_name}>
                <Typography.Paragraph style={{ fontSize: "1.2rem" }}>
                  "{testimonial.description}"
                </Typography.Paragraph>
                <Typography.Text type="secondary">
                  Written by {formatName(actions.findMember(testimonial))}
                </Typography.Text>
              </div>
            ))
          )}
        </div>
        <div className="button right" onClick={actions.onNext}>
          <RightOutlined />
        </div>
      </div>
    </div>
  );
}
