import React from "react";

/** Vendors */
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Typography } from "antd";

/** Custom CSS */
import "./problems.css";

const problems = [
  {
    description:
      "Of Real Estate Owners & Landlords use Spreadsheets to manage their portfolio",
    key: "problem-1",
    title: "70%+",
  },
  {
    description: (
      <React.Fragment>
        Of Real Estate Investors & Landlords found the <b>effectiveness</b> of
        their respective trackers to be <b>unsatisfactory</b>
      </React.Fragment>
    ),
    key: "problem-2",
    title: "73%+",
  },
  {
    description: (
      <React.Fragment>
        Of Real Estate Investors & Landlords found their&nbsp;
        <b>level of understanding</b>&nbsp;of how their portfolio is performing
        to be <b>unsatisfactory</b>
      </React.Fragment>
    ),
    key: "problem-3",
    title: "65%+",
  },
];

function Problems() {
  return (
    <Row className="problem shape-before shape-after">
      <div className="problem-container">
        <Row justify="center">
          <Col xs={24}>
            <br />
            <br />
            <Row justify="center" align="middle">
              <Col md={16} xs={22}>
                <Typography.Title className="text-offwhite" level={2}>
                  The Problem We Are Solving
                </Typography.Title>
                <br />
                <Typography.Paragraph className="text-light-green text-center sm-font">
                  There is a significant PROBLEM that Real Estate Owners &
                  Investors are facing across the country and TRACTIC is here to
                  help solve it!
                </Typography.Paragraph>
                <br />
              </Col>
            </Row>
            <div className="list">
              <Row className="list-row" gutter={[16, 16]} justify="center">
                {problems.map((item) => (
                  <Col key={item.key} md={6} xs={24}>
                    <Card>
                      <Card.Meta
                        description={item.description}
                        title={item.title}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <br />
        </Row>
        <br />
        <Col md={24}>
          <br /> <br />
          <Row justify="center">
            <Link to="/pricing">
              <Button size="large">Get Started Now</Button>
            </Link>
          </Row>
          <br />
        </Col>
        <br />
        <br />
        <br />
      </div>
    </Row>
  );
}

export default Problems;
