/** Vendors */
import axios from "axios";

/** Types */
import type { ISearchVideos, ISearchVideosResponse } from "types";

/* Member API Endpoints */
export async function searchVideos(
  params: ISearchVideos
): Promise<ISearchVideosResponse> {
  return await axios.get<ISearchVideos, ISearchVideosResponse>(
    "/video/search",
    {
      params: { ...params, callback: true },
    }
  );
}

const video = {
  search: searchVideos,
};

export default video;
