/** Vendors */
import axios from "axios";

async function memberContact(data: any) {
  return await axios.put("/member/contact", data);
}

const member = {
  contact: memberContact,
};

export default member;
