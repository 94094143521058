import { useEffect } from "react";

/** Vendors */
import { Typography } from "antd";

/** Redux */
import { searchPlatformMetricsAction } from "@redux/actions/platform";

/** Custom Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom CSS */
import "./platform_metrics.css";

/** Types */
import type { IPlatformMetrics } from "types";

export default function PlatformMetrics() {
  const dispatch = useAppDispatch();

  const { metrics = [] } = useAppSelector((state) => ({
    metrics: state.platform.metrics,
  }));

  useEffect(() => {
    dispatch(searchPlatformMetricsAction());
  }, []);

  return (
    <div className="platform-metrics">
      <div className="header">
        <Typography.Title className="m-0" level={3}>
          TRACTIC Member Activity
        </Typography.Title>
      </div>
      <div className="list">
        <div className="inner">
          {metrics.map((metric: IPlatformMetrics) => (
            <div className="platform-metric" key={metric.key}>
              <Typography.Title level={3}>{metric.value}</Typography.Title>
              <Typography.Paragraph>{metric.description}</Typography.Paragraph>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
