/** Vendors */
import { Link } from "react-router-dom";

const questions = [
  {
    answer: `TRACTIC streamlines your Real Estate asset management by providing 
    a platform that simplifies tracking and analyzing your Real Estate investments. 
    It offers tools for efficient bookkeeping, real-time performance assessment, 
    and data-driven decision-making, enhancing the profitability and management of 
    your properties. With TRACTIC, you gain clarity, control, and convenience in 
    managing your Real Estate portfolio.`,
    key: "faq-1",
    question: "What does TRACTIC do for me?",
  },
  {
    answer: `Absolutely, using TRACTIC significantly enhances your bookkeeping 
    efficiency as our platform automates data entry and provides real-time financial 
    tracking, which minimizes errors and saves valuable time. Additionally, TRACTIC's 
    customizable reporting tools ensure you have easy access to detailed financial 
    insights, streamlining your property or asset management process.`,
    key: "faq-2",
    question: "Does using TRACTIC improve my bookkeeping?",
  },
  {
    answer: `Yes, TRACTIC simplifies your tax filing process. Our platform 
    organizes financial data and generates comprehensive reports, 
    ensuring you have all necessary information readily available for tax purposes.`,
    key: "faq-3",
    question: "Does TRACTIC make filing my taxes easier?",
  },
  {
    answer: `TRACTIC’s 'White-Gloved Concierge' service is designed to take the 
    hassle out of your Real Estate asset management. Simply upload your files to our 
    platform and our team will take care of all the bookkeeping for you so sit back 
    and relax while our team handles the data entry, ensuring your transactional reports 
    and portfolio data are managed efficiently and accurately!`,
    key: "faq-4",
    question:
      "Manually inputting transactional reporting, data, and more from my portfolio will take time. How does TRACTIC help with this?",
  },
  {
    question:
      "How does TRACTIC formulate the data shown on my dashboard or individual property page?",
    answer: `TRACTIC calculates your portfolio's equity, vacancy rate,
    return on investment (ROI), and many more metrics using our vetted
    calculations. Selecting the 'i' icon within the
    respective key performance indicator card on your dashboard or
    property details page allows users to review each formula!`,
    key: "faq-5",
  },
  {
    question:
      "Can your team help me track the investment property performance for a deal I purchased a few years ago?",
    answer: `Our team would be more than pleased to review any/all owner
    statements, documents, receipts, pictures, etc. We ask that you
    subscribe either to the PRO or ALL STAR level subscription in
    order to allow our team to update your dashboard on your behalf.
    Remember to always review the uploaded information (you will
    receive a notification when we are done!) and contact us if we
    made any mistakes, if you have any questions or concerns, or if
    you'd like to let us know that we crushed it!`,
    key: "faq-6",
  },
  {
    question:
      "What happens if my financial, passive income goal (PIG) changes? How can this be displayed on my TRACTIC dashboard?",
    answer: `You can adjust or update your passive income goal whenever you
    like! Modifications can be made within your respective profile
    icon (bottom left side) on your TRACTIC dashboard.`,
    key: "faq-7",
  },
  {
    question:
      "How long does it take the TRACTIC platform to update my dashboard once I've uploaded new information?",
    answer: `All of the data is calculated real time. Once an update occurs you
    will see it display on the app.`,
    key: "faq-8",
  },
  {
    question:
      "How often will the TRACTIC team provide me with information and updates regarding my portfolio's performance?",
    answer: `Regardless of which subscription you choose, you will receive at
    least two notifications each month from us - we are here to help
    hold you accountable as a landlord and investor! These
    notifications will ask you update any recent transactions or even
    to review the updates made by the TRACTIC team. Further, you will
    also receive 90, 60, & 30 day reminders when your property
    insurance or rental lease(s) are about to expire!`,
    key: "faq-9",
  },
  {
    question:
      "How does TRACTIC gather accurate and up-to-date data for capital expenditures?",
    answer: `Capital Expenditures will be among the most significant charges
    you will face over time. With this in mind, our team has gathered
    the associated data & metrics from our network that allows us to
    give you more near-real insights regarding typical lifespans and
    replacement costs for major systems.`,
    key: "faq-10",
  },
  {
    question:
      "How long does it take the TRACTIC platform to update my dashboard once I've uploaded new information?",
    answer: `Uploaded information will dynamically update immediately on your
    TRACTIC dashboard and respective investment property details page.
    For All Star subscribers, our team asks for 24-48hrs to ensure
    that all of your uploaded investment information is set up
    correctly within your TRACTIC dashboard. You will receive a
    notification from our team once our team has completed the white
    glove concierge service, allowing you to review and let us know
    how we did!`,
    key: "faq-11",
  },
  {
    question:
      "Are my passive income goals (PIG), personal information, and investment information secure when stored in the TRACTIC platform?",
    answer: `From the beginning, our team designed this platform with data
    security in mind. TRACTIC was created using similar criteria to
    those used by the US military to safeguard and protect sensitive
    data.`,
    key: "faq-12",
    extras: (
      <span style={{ display: "inline" }}>
        <b>
          All connections are encrypted end-to-end, and your data is secured
          both at rest and in transit!
        </b>
      </span>
    ),
  },
  {
    question:
      "What is the length of commitment once I subscribe to the platform?",
    answer: `There is no obligation for any subscription. If you are not seeing
    the type of value you would expect from our team after your 60 day
    free trial, you are more than welcome to cancel your subscription!
    We do ask that you allow our team some time to get our feet
    underneath us as we roll out our second version launch -- please
    hit us with any questions, comments, or concerns at`,
    key: "faq-13",
    extras: (
      <span style={{ display: "inline" }}>
        <a className="text-color-1" href="mailto:support@tractic.io">
          support@tractic.io
        </a>
        &nbsp; to let us know how we can add more value to you!
      </span>
    ),
  },
  {
    question:
      "Does TRACTIC calculate market value and comps in the local area that I invest in?",
    answer: `TRACTIC will be able to assess more near-real market values and
    comps using transactional data from owners, landlords, and
    investors inside our network, allowing you to make better and more
    smarter investing decisions!`,
    key: "faq-14",
  },
  {
    question:
      "Can multiple users access the same subscription and/or share investment portfolio performance metrics? e.g., Partners",
    answer: `Any partners you have who help you reach your desired level of
    passive income FASTER work for us. We love the fact that our
    platform enables users to leverage our technological solutions
    with reliable partners or affiliates. So, in short, go for it`,
    key: "faq-15",
  },
  {
    question: "What is your privacy policy and do not share policy?",
    answer: `Our legal agreements can be found here:`,
    key: "faq-16",
    extras: (
      <ul>
        <br />
        <li>
          <Link
            className="text-color-1"
            target="_blank"
            to="/legal#privacy-policy"
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            className="text-color-1"
            target="_blank"
            to="/legal#terms-and-conditions"
          >
            Terms and Conditions
          </Link>
        </li>
        <li>
          <Link
            className="text-color-1"
            target="_blank"
            to="/legal#cookie-policy"
          >
            Cookie Policy
          </Link>
        </li>
      </ul>
    ),
  },
  {
    question:
      "Are my income and my property information secure when stored in the TRACTIC platform?",
    answer: `Our team built this platform with data security in mind from the
    start. TRACTIC was developed under similar guidelines that the
    United States Military follows to secure and protect its critical
    information. All connections are end-to-end encrypted, and your
    data while at rest and on the move are encrypted.`,
    key: "faq-17",
  },
  {
    question:
      "What is the length of commitment once I subscribe to the platform?",
    answer: `No commitment! You can cancel, upgrade or downgrade your service,
    anytime.`,
    key: "faq-18",
  },
  {
    question:
      "Does TRACTIC have the ability to track market value and comps in the local area of my properties?",
    answer: `This feature is coming soon to a TRACTIC subscription near you.`,
    key: "faq-19",
  },
  {
    question:
      "Can multiple users access the same subscription and/or share performance statistics? e.g. Partners",
    answer: `Partners that onboard with TRACTIC have the ability to support
    their clients through the administrative dashboard. This enables
    your clients to continue working with people they know and trust.
    You're just leveraging our technology and solutions...which
    is cool by us.`,
    key: "faq-20",
  },
];

export default questions;
