/** Vendors */
import axios from "axios";

/** Types */
import type { IPlatformMetricsResponse } from "../types";

/* Member API Endpoints */
export async function searchPlatformMetrics(): Promise<IPlatformMetricsResponse> {
  return await axios.get<null, IPlatformMetricsResponse>("/metrics/platform");
}

const platform = {
  metrics: { search: searchPlatformMetrics },
};

export default platform;
