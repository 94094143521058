/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch, IGetState } from "../../redux/configureStore";

/** Stripe Subscription Actions. */
export function searchPlatformMetricsAction() {
  return async (dispatch: IAppDispatch, _: IGetState, api: any) => {
    const response = await api.platform.metrics.search();
    if (response?.metrics) {
      dispatch({
        metrics: response.metrics,
        type: types.SEARCH_PLATFORM_METRICS_SUCCESS,
      });
    }
  };
}
