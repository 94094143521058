/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "@dist/js/redux";

/** Types */
import type { IDefinition } from "types";
import type { AnyAction, IRootState } from "@redux/configureStore";

const definition = createReducer(initialState.definition, (builder) => {
  builder
    .addCase(
      createAction(types.GET_DEFINITION_SUCCESS),
      (state: IRootState["definition"], action: AnyAction) => ({
        ...state,
        details: action.definition,
      })
    )
    .addCase(
      createAction(types.MATCHING_DEFINITIONS_SUCCESS),
      (state: IRootState["definition"], action: AnyAction) => {
        const definitions = action.results.sort(
          (a: IDefinition, b: IDefinition) => {
            return a.title?.localeCompare(b?.title);
          }
        );

        return {
          ...state,
          details: state?.definition?.resource_name
            ? state.definition
            : definitions?.[0],
          search: definitions,
        };
      }
    )
    .addCase(
      createAction(types.SEARCH_DEFINITIONS_SUCCESS),
      (state: IRootState["definition"], action: AnyAction) => ({
        ...state,
        list: redux
          .joinOrOverwrite(state, action)
          .list.sort((a, b) => a.title?.localeCompare(b?.title)),
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_DEFINITION_SUCCESS),
      (state: IRootState["definition"], action: AnyAction) => ({
        ...state,
        details: action.definition,
      })
    )
    .addDefaultCase((state) => state);
});

export default definition;
