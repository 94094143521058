export enum ResourceId {
  Article = "article",
  ChaChanel = "chat-channel",
  ChatMessage = "chat-message",
  Contact = "contact",
  File = "file",
  Member = "member",
  Notification = "notification",
  Property = "property",
  Setting = "setting",
  System = "system",
  Testimonial = "testimonial",
  Transaction = "transaction",
  Video = "video",
}

export enum StripeErrorCode {
  Active = "STRIPE_SUBSCRIPTION_ALREADY_ACTIVE",
  CancelSuccess = "CANCEL_SUBSCRIPTION_SUCCESS",
  CancelFailed = "CANCEL_SUBSCRIPTION_FAILED",
  Denied = "STRIPE_SUBSCRIPTION_DENIED",
  DiscountExpired = "STRIPE_SUBSCRIPTION_DISCOUNT_EXPIRED",
  DowngradeFailed = "DOWNGRADE_SUBSCRIPTION_FAILED",
  DowngradeSuccess = "DOWNGRADE_SUBSCRIPTION_SUCCESS",
  Error = "STRIPE_SUBSCRIPTION_ERROR",
  RequiresAction = "STRIPE_SUBSCRIPTION_REQUIRES_ACTION",
  RetryFailed = "STRIPE_RETRY_SUBSCRIPTION_FAILED",
  RetrySuccess = "STRIPE_RETRY_SUBSCRIPTION_SUCCESS",
  Success = "STRIPE_SUBSCRIPTION_SUCCESS",
  Unsubscribed = "STRIPE_SUBSCRIPTION_UNSUBSCRIBED",
  UpgradeFailed = "UPGRADE_SUBSCRIPTION_FAILED",
  UpgradeSuccess = "UPGRADE_SUBSCRIPTION_SUCCESS",
}

export enum StripePromoStatus {
  Invalid = "invalid",
  Valid = "valid",
}

export enum TestimonialCategory {
  DataManagement = "data_management",
  DocumentationStorage = "documentation_storage",
  OperationalEfficiency = "operational_efficiency",
  Overall = "overall",
  RealEstateScalability = "real_estate_scalability",
  ReportingAnalytics = "reporting_analytics",
  TaxReporting = "tax_reporting",
  Other = "other",
}

export enum VideoStatus {
  ApprovedPrivate = "approved_private",
  ApprovedPublic = "approved_public",
  Archived = "archived",
  Draft = "draft",
  InReview = "in_review",
}
