/** Redux */
import * as types from "./types";

/** Types */
import type { ISearchTestimonials } from "../../types";
import type { IAppDispatch, IGetState } from "../../redux/configureStore";

/** Stripe Subscription Actions. */
export function searchTestimonialAction(search: ISearchTestimonials) {
  return async (dispatch: IAppDispatch, _: IGetState, api: any) => {
    const response = await api.testimonial.search(search);
    if (!response?.results) return;
    dispatch({
      results: response.results,
      type: types.SEARCH_TESTIMONIALS_SUCCESS,
    });
    dispatch({
      results: response.members,
      type: types.SEARCH_MEMBERS_SUCCESS,
    });
  };
}
