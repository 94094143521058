/** Types */
import type {
  IArticle,
  IDefinition,
  IMember,
  IPlatformMetrics,
  IStripe,
  ITestimonial,
  IVideo,
} from "types";

export interface IInitialState {
  article: IStandardList<IArticle> & {
    highlights: IArticle[];
    search: IArticle[];
  };
  definition: IStandardList<IDefinition> & { search: IDefinition[] };
  member: IStandardList<IMember> & { stripe: IStripe };
  platform: {
    metrics: IPlatformMetrics[];
  };
  testimonial: IStandardList<ITestimonial>;
  video: IStandardList<IVideo> & { category: IStandardList<string> };
}

export interface IStandardList<T> {
  [key: string]: any;
  dataSetKey: string;
  list: Array<T>;
}

const state: IInitialState = {
  article: {
    dataSetKey: "",
    details: {} as IArticle,
    highlights: [] as IArticle[],
    list: [] as IArticle[],
    search: [] as IArticle[],
  },
  definition: {
    dataSetKey: "",
    details: {} as IDefinition,
    list: [] as IDefinition[],
    search: [] as IDefinition[],
  },
  member: {
    dataSetKey: "",
    details: {} as IMember,
    list: [] as IMember[],
    stripe: {
      error: null,
      coupon: {} as IStripe["coupon"],
      session: {} as IStripe["session"],
      subscription: {} as IStripe["subscription"],
    },
  },
  platform: {
    metrics: [] as IPlatformMetrics[],
  },
  testimonial: {
    dataSetKey: "",
    details: {} as ITestimonial,
    list: [] as ITestimonial[],
  },
  video: {
    category: {
      dataSetKey: "",
      details: "",
      list: [
        "Fundamentals",
        "Actively Track Your Passive Income",
        "Media Content",
      ],
    },
    dataSetKey: "",
    details: {} as IVideo,
    list: [
      {
        content_type: "youtube",
        created: 1709941098134,
        description:
          "In this video, we'll guide you through the simple process of reviewing an example owner statement and then adding specific transactions (money in/out) into the platform. ",
        resource_id: "video",
        category: "Fundamentals",
        resource_name: "video-0070590f-9882-49dc-b28e-666468053a10",
        title:
          "1. How to Easily Add Your Real Estate (MoM) Transactions on TRACTIC",
        updated: 1712948938366,
        status: "approved_public",
        timestamp: 1709941098134,
        signed_url: "https://youtu.be/PpUrbYkOFxQ",
      },
      {
        content_type: "youtube",
        created: 1709940264199,
        description:
          "Is passive income in Real Estate all it's cracked up to be? At Tractic.io, we believe in empowering Real Estate investors and landlords with the truth and the tools they need to truly assess the performance of their properties. Our platform is designed to debunk and set you on the path to real, data-driven decision-making in Real Estate.",
        resource_id: "video",
        category: "Actively Track Your Passive Income",
        resource_name: "video-81fb9c8b-ee7e-4a5f-8f89-c1873fd9466f",
        title: "Ad; Passive Income in Real Estate is a Fallacy",
        updated: 1712863727927,
        status: "approved_public",
        timestamp: 1709940264199,
        signed_url: "https://youtu.be/Q6aHvV02ehs",
      },
      {
        content_type: "youtube",
        created: 1710360974102,
        description:
          "Our guest for today on Living Off Rentals has over six years of experience as a founder and entrepreneur, having launched and scaled successful startups in the real estate sector.\n\n\nGreg Namrow is a former Marine Corps infantry officer who transitioned to being a real estate investor in 2017. He helps people make clear decisions based on data rather than on feelings. \n\n\nListen today and see the dramatic results when you take this approach to data-driven decision-making. Enjoy the show!\n\n\nTo listen to the complete interview go to: https://www.livingoffrentals.com/podcast \n\n\nREADY TO START YOUR OWN SHORT-TERM RENTAL BUSINESS? https://www.livingoffrentals.com/start\n",
        resource_id: "video",
        category: "Media Content",
        resource_name: "video-0002f3f4-6f6a-40c3-8a43-b1120cd6e8cb",
        title:
          "Living Off Rentals Podcast; The most important numbers real estate investors need to know",
        updated: 1712863708212,
        status: "approved_public",
        timestamp: 1710360974102,
        signed_url: "https://youtu.be/ERYJnFOHzeg",
      },
    ] as IVideo[],
  },
};

export default state;
