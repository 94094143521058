/** Vendors */
import ReactDOMServer from "react-dom/server";

/** Questions */
import questions from "../../../dist/data/Questions";

const schema = (props = {}) => {
  return {
    json: JSON.stringify([
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "FAQs",
            item: "https://www.tractic.io/faqs",
          },
        ],
      },
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          questions.map((props) => ({
            "@type": "Question",
            name: props.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: ReactDOMServer.renderToStaticMarkup(props.answer),
            },
          })),
        ],
      },
    ]),
    description: props.details.pagename,
    title: props.details.pagename,
  };
};
export default schema;
