/** Vendors */
import axios from "axios";

/** Types */
import type {
  ISearchDefinitionsPayload,
  ISearchDefinitionsResponse,
} from "../types";

async function searchDefinitions(params: ISearchDefinitionsPayload) {
  return await axios.get<null, ISearchDefinitionsResponse>(
    "definition/search",
    { params }
  );
}

const definition = {
  search: searchDefinitions,
};

export default definition;
