/** Vendors */
import axios from "axios";

/** Types */
import type {
  ISearchTestimonials,
  ISearchTestimonialsResponse,
} from "../types";

/* Member API Endpoints */
export async function searchTestimonials(
  params: ISearchTestimonials
): Promise<ISearchTestimonialsResponse> {
  return await axios.get<ISearchTestimonials, ISearchTestimonialsResponse>(
    "/testimonial/search",
    {
      params: { ...params, callback: true },
    }
  );
}

const testimonial = {
  search: searchTestimonials,
};

export default testimonial;
