/** Vendors */
import axios from "axios";

/** Types */
import type {
  IStripePromoPayload,
  IStripePromoResponse,
  IStripeSessionPayload,
  IStripeSessionResponse,
  IStripeSubscriptionPayload,
  IStripeSubscriptionResponse,
} from "../types";

async function createSession(payload: IStripeSessionPayload) {
  return await axios.put<IStripeSessionPayload, IStripeSessionResponse>(
    "/stripe/session",
    payload
  );
}

async function createSubscription(payload: IStripeSubscriptionPayload) {
  return await axios.put<
    IStripeSubscriptionPayload,
    IStripeSubscriptionResponse
  >("/stripe/subscription", payload);
}

async function verifyPromo(payload: IStripePromoPayload) {
  try {
    return await axios.post<IStripePromoPayload, IStripePromoResponse>(
      "/stripe/promo/verify",
      payload
    );
  } catch (error) {
    console.log({ error });
  }
}

const stripe = {
  create: {
    session: createSession,
    subscription: createSubscription,
  },
  verify: {
    promo: verifyPromo,
  },
};

export default stripe;
