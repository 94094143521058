/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "@redux/configureStore";

const video = createReducer(initialState.video, (builder) => {
  builder
    .addCase(
      createAction(types.SET_ACTIVE_VIDEO_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return { ...state, details: action.video };
      }
    )
    .addCase(
      createAction(types.SEARCH_VIDEOS_SUCCESS),
      (state: IRootState["video"], action: AnyAction) => {
        return {
          ...state,
          category: { ...state.category, list: action.categories || [] },
          list: action.results,
        };
      }
    )
    .addDefaultCase((state) => state);
});

export default video;
