import { useState } from "react";

/** Vendors */
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Input, Row, Typography, notification } from "antd";

/** Custom API */
import memberApi from "../../../api/member";

/** Custom CSS */
import "./demonstration.css";

const SubmitButton = ({ loading }: { loading: boolean }) => (
  <div
    style={{
      borderBottomRightRadius: "8px",
      borderTopRightRadius: "8px",
    }}
  >
    Submit {loading && <LoadingOutlined />}
  </div>
);

function Demonstration() {
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const actions = {
    onFinish: async function (email = "") {
      if (!email.includes("@")) {
        setIsError(true);
      } else {
        setIsError(false);
        setLoading(true);

        await memberApi.contact({
          lead: {
            email,
            notes: "Product Demonstration Request",
          },
        });

        setLoading(false);

        notification.success({
          description:
            "We have received your demonstration request and will be in touch shortly.",
          message: "Success!",
        });
      }
    },
  };

  return (
    <Row className="demonstration" align="middle">
      <Col>
        <br />
        <br />
        <br />
        <Row>
          <Col lg={24} className="flex flex-column text-center">
            <Typography.Text>
              Looking for product demonstration?
            </Typography.Text>
            <Typography.Text>
              Leave your email and we will provide one, free of charge.
            </Typography.Text>
            <br />
            <br />
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col lg={24} md={24} sm={23} xs={23}>
            <Input.Search
              allowClear
              enterButton={<SubmitButton loading={loading} />}
              onChange={(e) => {
                if (e.target.value.length > 0) setIsError(false);
              }}
              onSearch={actions.onFinish}
              placeholder="Your email address"
              size="large"
              style={{
                width: "100%",
              }}
            />
          </Col>
          <div
            style={{
              color: "white",
              visibility: isError ? "visible" : isError ? "hidden" : "visible",
            }}
          >
            {isError && "Email is required"}
          </div>
        </Row>
        <br />
        <br />
        <br />
      </Col>
    </Row>
  );
}

export default Demonstration;
