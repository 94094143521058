/** Vendor */
import { Card, Col, Row, Typography } from "antd";

/** Images */
import IconBell from "@dist/img/icons/icon-bell.png";
import IconCoin from "@dist/img/icons/icon-coin.png";
import IconDash from "@dist/img/icons/icon-dash.png";
import IconHome from "@dist/img/icons/icon-home.png";

/** Custom CSS */
import "./overview.css";

const data = [
  {
    icon: IconCoin,
    title: "Analytics",
    key: "overview-1",
    description:
      "Assess your assumptions against how your investments are actually performing",
  },
  {
    icon: IconBell,
    title: "Accountability Notifications",
    key: "overview-2",
    description:
      "Receive accountability notifications to ensure you are always on top of your deal(s)",
  },
  {
    icon: IconHome,
    title: "Property Tracking",
    key: "overview-3",
    description:
      "Evaluate whether or not your investment(s) are operating as expected",
  },
  {
    icon: IconDash,
    title: "Dashboard",
    key: "overview-4",
    description: "Track your % complete for your passive income goal (PIG)",
  },
];

function Overview() {
  return (
    <Row justify="center" className="overview">
      <Col xs={24}>
        <Typography.Title level={2}>
          Accurate Portfolio Analysis in Just a Few Clicks
        </Typography.Title>
        <Typography.Paragraph type="secondary" className="sm-font">
          TRACTIC offers Real Estate owners and investors tools to monitor their
          property's performance, including occupancy, equity, and more. Users
          also get tailored alerts for things like lease expirations, insurance
          coverage, and unexpected maintenance costs.
        </Typography.Paragraph>
        <br />
        <br />
        <div className="list">
          {data.map((item) => (
            <Card key={item.key} bordered={false} className="card">
              <img src={item.icon} alt="logo" />
              <Typography.Title className="text-center" level={5}>
                {item.title}
              </Typography.Title>
              <br />
              <Typography.Paragraph
                type="secondary"
                className="text-center xs-font"
              >
                {item.description}
              </Typography.Paragraph>
              <br />
            </Card>
          ))}
        </div>
      </Col>
    </Row>
  );
}

export default Overview;
