/* Articles Actions */
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const MATCHING_ARTICLES_SUCCESS = "MATCHING_ARTICLES_SUCCESS";
export const SEARCH_ARTICLES_SUCCESS = "SEARCH_ARTICLES_SUCCESS";
export const SEARCH_HIGHLIGHT_ARTICLES_SUCCESS =
  "SEARCH_HIGHLIGHT_ARTICLES_SUCCESS";
export const SET_ACTIVE_ARTICLE_SUCCESS = "SET_ACTIVE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";

/* Definition Actions */
export const GET_DEFINITION_SUCCESS = "GET_DEFINITION_SUCCESS";
export const MATCHING_DEFINITIONS_SUCCESS = "MATCHING_DEFINITIONS_SUCCESS";
export const SEARCH_DEFINITIONS_SUCCESS = "SEARCH_DEFINITIONS_SUCCESS";
export const SET_ACTIVE_DEFINITION_SUCCESS = "SET_ACTIVE_DEFINITION_SUCCESS";

/* Members Actions */
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const SEARCH_MEMBERS_SUCCESS = "SEARCH_MEMBERS_SUCCESS";
export const SET_ACTIVE_MEMBER_SUCCESS = "SET_ACTIVE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";

/** Platform Actions */
export const SEARCH_PLATFORM_METRICS_SUCCESS =
  "SEARCH_PLATFORM_METRICS_SUCCESS";

/* Stripe Actions */
export const STRIPE_SESSION_CREATE_SUCCESS = "STRIPE_SESSION_CREATE_SUCCESS";
export const STRIPE_SUBSCRIPTION_CREATE_SUCCESS =
  "STRIPE_SUBSCRIPTION_CREATE_SUCCESS";
export const STRIPE_SUBSCRIPTION_DENIED_SUCCESS =
  "STRIPE_SUBSCRIPTION_DENIED_SUCCESS";
export const STRIPE_SUBSCRIPTION_ERROR_SUCCESS =
  "STRIPE_SUBSCRIPTION_ERROR_SUCCESS";
export const STRIPE_SUBSCRIPTION_DISCOUNT_EXPIRED_SUCCESS_SUCCESS =
  "STRIPE_SUBSCRIPTION_DISCOUNT_EXPIRED_SUCCESS";
export const STRIPE_SUBSCRIPTION_REQUIRES_ACTION_SUCCESS =
  "STRIPE_SUBSCRIPTION_REQUIRES_ACTION_SUCCESS";
export const STRIPE_SUBSCRIPTION_RETRY_SUCCESS =
  "STRIPE_SUBSCRIPTION_RETRY_SUCCESS";
export const STRIPE_SUBSCRIPTION_SUCCESS = "STRIPE_SUBSCRIPTION_SUCCESS";
export const STRIPE_UPDATE_SUBSCRIPTION_SUCCESS =
  "STRIPE_UPDATE_SUBSCRIPTION_SUCCESS";
export const STRIPE_VERIFY_PROMO_SUCCESS = "STRIPE_VERIFY_PROMO_SUCCESS";

/* Testimonial Actions */
export const GET_TESTIMONIAL_SUCCESS = "GET_TESTIMONIAL_SUCCESS";
export const SEARCH_TESTIMONIALS_SUCCESS = "SEARCH_TESTIMONIALS_SUCCESS";

/** Video Actions */
export const SET_ACTIVE_VIDEO_SUCCESS = "SET_ACTIVE_VIDEO_SUCCESS";
export const SEARCH_VIDEOS_SUCCESS = "SEARCH_VIDEOS_SUCCESS";
export const SEARCH_VIDEO_CATEGORIES_SUCCESS =
  "SEARCH_VIDEO_CATEGORIES_SUCCESS";
