const schema = () => {
  return {
    json: JSON.stringify([
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "Definitions",
        url: "https://www.tractic.io/education/definitions",
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: "https://www.tractic.io",
            name: "Home",
          },
          {
            "@type": "ListItem",
            position: 2,
            item: "https://www.tractic.io/education",
            name: "Education",
          },
          {
            "@type": "ListItem",
            position: 3,
            item: "https://www.tractic.io/education/definitions",
            name: "Definitions",
          },
        ],
      },
    ]),
    description:
      "Collection of definitions and how we analyze data within our platform",
    title: "Definitions",
  };
};
export default schema;
