/** Logos */
import AWBLogo from "@dist/img/partner/awb-logo.png";
import CornerStoneLogo from "@dist/img/partner/cornerstone-logo.png";
import FMTMLogo from "@dist/img/partner/fmtm-logo.svg";
import LORLogo from "@dist/img/partner/lor-logo.png";
import ObieLogo from "@dist/img/partner/obie-logo.svg";
import PCSLogo from "@dist/img/partner/pcs-logo.png";
import REILogo from "@dist/img/partner/rei-logo.png";
import SteadilyLogo from "@dist/img/partner/steadily-logo.svg";
import StepLogo from "@dist/img/partner/step-logo.png";
import TrueBooks from "@dist/img/partner/truebooks-logo.png";

/** Custom CSS */
import "./partners.css";

function PartnerLogos() {
  const list = [
    { alt: "Alliance Wealth Builders", key: "partner-option-1", icon: AWBLogo },
    { alt: "TrueBooks", key: "partner-option-2", icon: TrueBooks },
    {
      alt: "Obie Insurance",
      key: "partner-option-3",
      icon: ObieLogo,
    },
    {
      alt: "Cornerstone First Mortgage",
      key: "partner-option-4",
      icon: CornerStoneLogo,
    },
    {
      alt: "Steadily Insurance",
      key: "partner-option-5",
      icon: SteadilyLogo,
    },
    {
      alt: "Prime Corporate Services",
      key: "partner-option-6",
      icon: PCSLogo,
    },
    {
      alt: "Living Off Rentals",
      key: "partner-option-7",
      icon: LORLogo,
    },
    {
      alt: "Support The Enlisted Project (STEP)",
      key: "partner-option-8",
      icon: StepLogo,
    },
    {
      alt: "From Military To Millionaire",
      key: "partner-option-9",
      icon: FMTMLogo,
    },
    {
      alt: "Support The Enlisted Project (STEP)",
      key: "partner-option-10",
      icon: REILogo,
    },
  ];

  return (
    <div className="partners-list" style={{ "--pcount": list.length }}>
      <div className="container">
        {list.map((option) => (
          <div className="item" key={option.key}>
            <img alt={option.alt} src={option.icon} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PartnerLogos;
