const schema = (props = {}) => {
  return {
    json: JSON.stringify([
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: `${props.details.pagename}`,
        url: `https://www.tractic.io/${props.details.pagename?.toLowerCase()}`,
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: "https://www.tractic.io",
            name: "Home",
          },
          {
            "@type": "ListItem",
            position: 2,
            item: `https://www.tractic.io/${props.details.pagename?.toLowerCase()}`,
            name: props.details.pagename,
          },
        ],
      },
    ]),
    description: `${props.details.pagename}`,
    title: `${props.details.pagename}`,
  };
};
export default schema;
