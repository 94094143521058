/** Vendors */
import { combineReducers } from "@reduxjs/toolkit";

/** Custom Reducers */
import article from "./article";
import definition from "./definition";
import member from "./member";
import platform from "./platform";
import testimonial from "./testimonial";
import video from "./video";

const rootReducer = combineReducers({
  article,
  definition,
  member,
  platform,
  testimonial,
  video,
});

export default rootReducer;
