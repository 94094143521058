/** Vendor */
import { Link } from "react-router-dom";
import { Button, Col, Row, Space, Typography } from "antd";

/** Custom Assets */
import HeroBackground from "@dist/img/app/hero.jpg";
import HeroFore from "@dist/img/app/hero-fore.png";

/** Custom CSS */
import "./hero.css";

function Hero() {
  return (
    <div className="overflow-image">
      <Row
        className="hero"
        style={{
          backgroundImage: `url(${HeroBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "90vh",
        }}
      >
        <Col className="fill-parent">
          <Row className="fill-parent" align={"middle"}>
            <Col className="title-col" sm={24} md={24} lg={14} xl={12}>
              <Typography.Title className="text-white">
                Analyze the performance of your Real Estate portfolio in
                real-time
              </Typography.Title>
              <Typography.Text className="sm-font text-light-green">
                TRACTIC is a Real Estate asset management platform enabling
                owners, investors, and landlords to assess the real-time
                performance of their portfolios
              </Typography.Text>
              <br />
              <Space size="middle">
                <Link to="/pricing">
                  <Button size="large">Get Started Now</Button>
                </Link>
                <Button
                  href="https://youtu.be/QkA-5HiqUqc"
                  size="large"
                  target="_blank"
                  ghost
                >
                  View All Features
                </Button>
              </Space>
              <br />
              <br />
              <Row align={"middle"}>
                <Typography.Text className="login">
                  Already have an account?
                </Typography.Text>
                <Typography.Link
                  className="login ml-1"
                  href="https://app.tractic.io/dashboard"
                  underline
                >
                  Log in
                </Typography.Link>
              </Row>
            </Col>
            <Col className="img-col" sm={24} md={24} lg={10} xl={12}>
              <Row className="img-row w-100" justify={"end"} align={"middle"}>
                <img
                  style={{
                    backgroundImage: `url(${HeroFore})`,
                    backgroundSize: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px 0 0 10px",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.50)",
                    objectFit: "contain",
                  }}
                ></img>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Hero;
