/** Vendors */
import { Link } from "react-router-dom";
import { Col, Grid, Layout, Row, Typography } from "antd";

/** Custom Assets */
import FooterLogo from "@dist/img/brand/tractic-white-logo.png";

/** Custom CSS */
import "./footer.css";

/** Types */
interface Link {
  name: string;
  link: string;
}

const { useBreakpoint } = Grid;

const links = [
  {
    name: "Home",
    link: "./",
  },
  {
    name: "Pricing",
    link: "./pricing",
  },
  {
    name: "Education",
    link: "./education",
  },
  {
    name: "How To Videos",
    link: "./education/tutorials",
  },
  {
    name: "Articles",
    link: "./education/articles",
  },
  {
    name: "Features",
    link: "./education/definitions",
  },
  {
    name: "Contact Us",
    link: "./contact",
  },
  {
    name: "FAQs",
    link: "/faqs",
  },
  {
    name: "Legal",
    link: "./legal",
  },
  {
    name: "Facebook",
    link: "https://facebook.com/tractic.io?utm_source=public_app",
  },
  {
    name: "Instagram",
    link: "https://instagram.com/_tractic_io/?utm_source=public_app",
  },
  {
    name: "Linkedin",
    link: "https://linkedin.com/company/tracticio?utm_source=public_app",
  },
];

function Footer() {
  const { md } = useBreakpoint();

  return (
    <Layout.Footer>
      <Row justify="center" className="bg-white">
        <Col md={18} xs={22}>
          <Row justify="start">
            <Col md={9} xs={24}>
              <img src={FooterLogo} width="250px" />
              <Col xs={0} md={24}>
                <br />
                <Typography.Text className="text-grey copyright-text">
                  &copy; {new Date().getFullYear()} TRACTIC LLC.
                </Typography.Text>
              </Col>
              <br />
              <br />
              <br />
            </Col>
            {(md ? [4, 4, 4] : [6, 6]).map((col, i) => (
              <Col key={`link-column-${i}`} md={5} xs={12}>
                {links.slice(i * col, (i + 1) * col).map((link) => (
                  <Link className="link" key={link.link} to={link.link}>
                    {link.name}
                  </Link>
                ))}
              </Col>
            ))}

            <Col xs={24} md={0}>
              <br />
              <br />
              <Typography.Text className="text-grey copyright-text">
                &copy; {new Date().getFullYear()} TRACTIC LLC.
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Footer>
  );
}

export default Footer;
