/** Enums */
import { TestimonialCategory } from "../../types";

export function formatAddress(params) {
  const parts = [];
  if (params.address) parts.push(params.address);
  if (params.line1) parts.push(params.line1);
  if (params.city) parts.push(`, ${params.city}`);
  if (params.state) parts.push(params.state);
  if (params.postal_code) parts.push(params.postal_code);
  if (params.zip_code) parts.push(params.zip_code);
  return parts.join(" ");
}

export function formatCellphone(cell) {
  var cleaned = ("" + cell).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    //var intlCode = match[1] ? "+1" : "";
    return ["+1", match[2], match[3], match[4]].join("");
  }
  return null;
}

export function formatMoney(text = "") {
  if (!text) return "$0";
  const isNegative = isNaN(text) ? "" : text < 0 ? "-" : "";
  text = (isNaN(text) ? 0 : Number(text)).toFixed(2);

  if (text.includes(".")) text = text.slice(0, -3);
  text = formatNumber(text);
  return `${isNegative}${formatter.format(text)}`;
}

export function formatName(details = {}) {
  let name = [];
  if (details.given_name) name.push(details.given_name);
  if (details.family_name) name.push(details.family_name);
  return name.length ? name.join(" ") : "No Name";
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatNumber(str = "") {
  return Number((str || "").replace(/[^0-9.]/g, ""));
}

export const testimonialCategories = [
  {
    key: "testimonial-category-0",
    label: "Overall",
    value: TestimonialCategory.Overall,
  },
  {
    key: "testimonial-category-1",
    label: "Data Management",
    value: TestimonialCategory.DataManagement,
  },
  {
    key: "testimonial-category-2",
    label: "Documentation & Storage Management",
    value: TestimonialCategory.DocumentationStorage,
  },
  {
    key: "testimonial-category-3",
    label: "Operational Efficiency",
    value: TestimonialCategory.OperationalEfficiency,
  },
  {
    key: "testimonial-category-4",
    label: "Real Estate Scalability",
    value: TestimonialCategory.RealEstateScalability,
  },
  {
    key: "testimonial-category-5",
    label: "Reporting Analytics",
    value: TestimonialCategory.ReportingAnalytics,
  },
  {
    key: "testimonial-category-6",
    label: "Tax Reporting",
    value: TestimonialCategory.TaxReporting,
  },
  {
    key: "testimonial-category-7",
    label: "Other",
    value: TestimonialCategory.Other,
  },
];

export function toTitleCase(str = "") {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
