/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "@dist/js/redux";

/** Types */
import type { IArticle } from "types";
import type { AnyAction, IRootState } from "@redux/configureStore";

const article = createReducer(initialState.article, (builder) => {
  builder
    .addCase(
      createAction(types.GET_ARTICLE_SUCCESS),
      (state: IRootState["article"], action: AnyAction) => ({
        ...state,
        details: action.article,
      })
    )
    .addCase(
      createAction(types.MATCHING_ARTICLES_SUCCESS),
      (state: IRootState["article"], action: AnyAction) => {
        const articles = action.results.sort((a: IArticle, b: IArticle) =>
          a.title?.localeCompare(b?.title)
        );
        return {
          ...state,
          details: state?.article?.resource_name
            ? state.article
            : articles?.[0],
          search: articles,
        };
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_ARTICLE_SUCCESS),
      (state: IRootState["article"], action: AnyAction) => ({
        ...state,
        details: action.article,
      })
    )
    .addCase(
      createAction(types.SEARCH_HIGHLIGHT_ARTICLES_SUCCESS),
      (state: IRootState["article"], action: AnyAction) => ({
        ...state,
        highlights: redux.joinOrOverwrite(state, action).list,
      })
    )
    .addCase(
      createAction(types.SEARCH_ARTICLES_SUCCESS),
      (state: IRootState["article"], action: AnyAction) => ({
        ...state,
        list: redux.joinOrOverwrite(state, action)?.list,
        search: redux.joinOrOverwrite(state, action)?.list,
      })
    )
    .addCase(
      createAction(types.UPDATE_ARTICLE_SUCCESS),
      (state: IRootState["article"], action: AnyAction) => {
        return { ...state, ...redux.updateMatchCase(state, action, "article") };
      }
    )
    .addDefaultCase((state) => state);
});

export default article;
